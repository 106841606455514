<template>
  <LoginContainer>
    <template v-slot:lottie>
      <RenderImage :lottie="lottie" />
    </template>
    <template v-slot:content>
      <router-view />
    </template>
  </LoginContainer>
</template>

<script>
import { LoginContainer } from '@seliaco/red-panda'
import RenderImage from '@/components/misc/RenderImage'
export default {
  name: 'WrapperAuth',
  data () {
    return {
      lottie: 'https://igpedxmysqtwteudvnol.supabase.co/storage/v1/object/public/lottie/lottie-login.json'
    }
  },
  components: { LoginContainer, RenderImage }
}
</script>
